import axios from 'axios';
import config from '../config';
import { store } from '../store';
import { showError } from '../utils';

export function getHraForm() {
  const { authToken, userId } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/health-risk-assessment/${userId}/form`, {
      headers,
    })
    .then(resp => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'end'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function submitHraResponse(question) {
  const { authToken, userId } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/health-risk-assessment/${userId}/form`,
      question,
      { headers },
    )
    .then(resp => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'end'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function goToPreviousHra(parentOrder, questionOrder) {
  const { authToken, userId } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${
        config.apiBaseUrl
      }/health-risk-assessment/${userId}/form/${parentOrder}/${questionOrder}`,
      { headers },
    )
    .then(resp => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'end'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function resetHra() {
  const { authToken, userId } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/health-risk-assessment/${userId}/form/reset`, {
      headers,
    })
    .then(resp => {
      const responseBody = resp.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'end'
      ) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      throw err;
    });
}

export function getHraPdfUrl() {
  const { authToken } = store.getState().user;

  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/health-risk-assessment/hra-report-as-pdf`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
