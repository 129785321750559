import styled from 'styled-components';
import Modal from '../common/Modal';
import HourGlass from '../../images/onboarding/hour-glass.svg';

const OuterContainer = styled.div`
  width: 100%;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

function PolicyWaitingPeriodModal() {
  /**
   * Clicking outside will not close the Modal
   */
  function handleOutsideClick() {
    // Do nothing
  }

  function closeSDK() {
    if (window?.Android?.closeView) {
      return window.Android.closeView();
    } else {
      window?.history?.back();
    }
  }

  return (
    <Modal>
      <ModalContainer onClick={handleOutsideClick}>
        <div className="bg-textbox w-80 p-20 rounded-lg flex flex-col text-center">
          <img src={HourGlass} alt="HourGlass" className="p-20" />
          <h2 className="text-txtlight text-title-xl font-bold ">
            Waiting period is not over yet
          </h2>
          <p className="text-txtsecondary-shades8 text-body-s p-15">
            You can claim your benefits once the 48 hour waiting period is over.
            <br />
          </p>
          <button
            className="primary bg-primary rounded-12 text-textbox text-body-s py-3"
            onClick={closeSDK}
          >
            Continue
          </button>
        </div>
      </ModalContainer>
    </Modal>
  );
}

export default function PolicyWaitingPeriod() {
  return (
    <OuterContainer>
      <PolicyWaitingPeriodModal />
    </OuterContainer>
  );
}
