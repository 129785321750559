const config = {
  development: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    rootUrl: 'https://web.samuraijack.xyz/',
    // apiBaseUrl: 'https://api.getvisitapp.xyz/v3',
    // apiBaseUrl: 'https://api.getvisitapp.xyz/v3',
    apiBaseUrl: 'http://localhost:5001',
    opdPurchaseUrl: 'https://opd.samuraijack.xyz',
    websiteBaseUrl: 'https://samuraijack.xyz/',
    pharmaServer: 'https://absol.getvisitapp.xyz',
    // pharmaServer: 'http://localhost:2020',

    version: '161',
    amplitudeApiKey: '2bfbcb774f5fd6372197d0e43fbda536',
    opentokApiKey: '45467122',
    clevertapId: '585-5KR-KR6Z',
  },
  staging: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    rootUrl: 'https://ola.getvisitapp.xyz/',
    apiBaseUrl: 'https://api.getvisitapp.xyz/v3',
    opdPurchaseUrl: 'https://opd.samuraijack.xyz',
    websiteBaseUrl: 'https://samuraijack.xyz/',
    pharmaServer: 'https://absol.getvisitapp.xyz',
    version: '161',
    amplitudeApiKey: '2bfbcb774f5fd6372197d0e43fbda536',
    opentokApiKey: '45467122',
    clevertapId: '585-5KR-KR6Z',
  },
  production: {
    pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
    pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    rootUrl: 'https://ola.getvisitapp.com/',
    apiBaseUrl: 'https://api.getvisitapp.com/v4',
    opdPurchaseUrl: 'https://buy.getvisitapp.com',
    websiteBaseUrl: 'https://getvisitapp.com/',
    pharmaServer: 'https://api.getvisitapp.com/pharmacy',
    version: '161',
    amplitudeApiKey: 'c1ee11161ba6d54bb7df1b932c048dd0',
    opentokApiKey: '45440642',
    clevertapId: '485-5KR-KR6Z',
  },
};

export default config[process.env.NODE_ENV];
